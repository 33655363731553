/*
import React, { useState } from "react";
import { getFirestore, doc, updateDoc } from "firebase/firestore";

const DeleteHistory = ({ selectedPatient, onClose }) => {
  const [selectedKey, setSelectedKey] = useState(""); // Clave del antecedente a eliminar
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Obtener antecedentes del paciente seleccionado
  const { background } = selectedPatient || {};

  const handleDelete = async (e) => {
    e.preventDefault();

    if (!selectedKey) {
      setError("Por favor, selecciona un antecedente para eliminar.");
      return;
    }

    try {
      const db = getFirestore();
      const patientRef = doc(db, "registeredPatients", selectedPatient.id);

      // Crear un nuevo objeto `background` sin la clave seleccionada
      const updatedBackground = { ...background };
      delete updatedBackground[selectedKey];

      // Actualizar Firestore
      await updateDoc(patientRef, { background: updatedBackground });

      setSuccess("Antecedente eliminado con éxito.");
      setError("");
      setSelectedKey("");
    } catch (error) {
      console.error("Error al eliminar el antecedente:", error);
      setError("Hubo un problema al eliminar el antecedente.");
    }
  };

  return (
    <div className="deleteHistory">
      <h2>Eliminar Antecedente</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}

      <form onSubmit={handleDelete}>
        <div>
          <label>Selecciona un antecedente:</label>
          <select
            value={selectedKey}
            onChange={(e) => setSelectedKey(e.target.value)}
          >
            <option value="">-- Selecciona --</option>
            {background &&
              Object.keys(background).map((key, index) => (
                <option key={index} value={key}>
                  {key} - {background[key]}
                </option>
              ))}
          </select>
        </div>
        <button type="submit">Eliminar</button>
      </form>
    </div>
  );
};

export default DeleteHistory;
*/

import React, { useEffect, useState } from "react";
import { getFirestore, doc, onSnapshot, updateDoc } from "firebase/firestore";

const DeleteHistory = ({ selectedPatient, onClose }) => {
  const [background, setBackground] = useState({});
  const [selectedKey, setSelectedKey] = useState(""); // Clave del antecedente a eliminar
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (!selectedPatient) return;

    const db = getFirestore();
    const patientRef = doc(db, "registeredPatients", selectedPatient.id);

    // Configurar el listener para escuchar cambios en tiempo real
    const unsubscribe = onSnapshot(
      patientRef,
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setBackground(data.background || {}); // Actualizar antecedentes
        } else {
          console.error("El documento no existe.");
          setBackground({});
        }
      },
      (error) => {
        console.error("Error al escuchar los cambios:", error);
        setError("Hubo un problema al cargar los antecedentes.");
      }
    );

    // Cleanup del listener cuando el componente se desmonta
    return () => unsubscribe();
  }, [selectedPatient]);

  const handleDelete = async (e) => {
    e.preventDefault();

    if (!selectedKey) {
      setError("Por favor, selecciona un antecedente para eliminar.");
      return;
    }

    try {
      const db = getFirestore();
      const patientRef = doc(db, "registeredPatients", selectedPatient.id);

      // Crear un nuevo objeto `background` sin la clave seleccionada
      const updatedBackground = { ...background };
      delete updatedBackground[selectedKey];

      // Actualizar Firestore
      await updateDoc(patientRef, { background: updatedBackground });

      setSuccess("Antecedente eliminado con éxito.");
      setError("");
      setSelectedKey("");
    } catch (error) {
      console.error("Error al eliminar el antecedente:", error);
      setError("Hubo un problema al eliminar el antecedente.");
    }
  };

  return (
    <div className="deleteHistory">
      <h2>Eliminar Antecedente</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}

      <form onSubmit={handleDelete}>
        <div>
          <select
            value={selectedKey}
            onChange={(e) => setSelectedKey(e.target.value)}
            className="generalFormInput"
          >
            <option value="">-- Selecciona --</option>
            {background &&
              Object.keys(background).map((key, index) => (
                <option key={index} value={key}>
                  {key} - {background[key]}
                </option>
              ))}
          </select>
        </div>
        <button type="submit">Eliminar</button>
      </form>
    </div>
  );
};

export default DeleteHistory;
