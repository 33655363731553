//IMPORTACIONES INTERNAS
import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../shared/firebase/firebase";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

//IMPORTACIONES DE ARCHIVOS PROPIOS
import { useAuth } from "../../shared/context/AuthContext";
import "../styles/Login.css";
import "../../shared/styles/Global.css";
import { getAuthErrorMessage } from "../services/authErrorHandler";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/account");
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();

    // Validar campos vacíos
    if (!email || !password) {
      toast.error("Por favor, completa todos los campos.");
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, email, password);
      login();
      toast.success("Inicio de sesión exitoso.");
      navigate("/account");
    } catch (err) {
      const errorMessage = getAuthErrorMessage(err);
      toast.error(errorMessage);
    }
  };

  return (
    <section className="loginSection">
      <div className="presentationContainer">
        <img src="/images/patientManager1.svg" alt="" />
        <p>
          La primera plataforma para gestionar pacientes de forma simple y
          efectiva.
        </p>
      </div>

      <div className="loginContainer">
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Correo electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <input
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <br />
          <button type="submit">Iniciar sesión</button>
        </form>

        <a href="">
          {" "}
          <p>Olvide mi contraseña.</p>
        </a>

        <a href="">
          {" "}
          <p>No tengo cuenta, quiero registrarme.</p>
        </a>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
}

export default Login;
