import React, { useState } from "react";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import getUserGroup from "../../shared/components/getUserGroup"; // Para obtener el usuario autenticado

const AddEvolution = ({ selectedPatient, onClose }) => {
  const [observation, setObservation] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!observation.trim()) {
      setError("La observación no puede estar vacía.");
      return;
    }

    try {
      const db = getFirestore();
      const patientRef = doc(db, "registeredPatients", selectedPatient.id);

      // Generar fecha actual en formato aaaa-mm-dd
      const today = new Date().toISOString().split("T")[0];

      // Obtener usuario autenticado (nombre y apellido)
      /*
      const userGroup = await getUserGroup();
      const userFullName = `${userGroup.name} ${userGroup.surname}`;
      */
      const userData = await getUserGroup(true);
      const userFullName = `${userData.name} ${userData.surname}`;

      // Generar clave dinámica: Evolución_fecha_x
      const evolutionKey = `Evolución_${today}_${Date.now()}`;

      // Nueva evolución a agregar
      const newEvolution = {
        observation,
        date: today,
        user: userFullName,
      };

      // Actualizar el mapa 'evolutions' dentro del documento del paciente
      await updateDoc(patientRef, {
        [`evolutions.${evolutionKey}`]: newEvolution,
      });

      setSuccess("Evolución agregada con éxito.");
      setObservation(""); // Limpiar campo
      setError("");
    } catch (error) {
      console.error("Error al agregar evolución:", error);
      setError("Hubo un problema al guardar la evolución.");
    }
  };

  return (
    <div>
      <h2>Agregar Evolución</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}

      <form onSubmit={handleSubmit}>
        <div>
          <textarea
            id="observation"
            name="observation"
            value={observation}
            onChange={(e) => setObservation(e.target.value)}
            className="generalFormInput"
            placeholder="Escribe la observación..."
            rows="4"
          ></textarea>
        </div>
        <button type="submit">Guardar</button>
      </form>
    </div>
  );
};

export default AddEvolution;
