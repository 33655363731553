import { getFirestore, doc, getDoc } from "firebase/firestore";
import getUserUID from "../../shared/components/getUserUID";

const getCompleteUserData = async () => {
  try {
    const userUID = await getUserUID();
    if (!userUID) {
      console.warn("El usuario no está autenticado.");
      return null;
    }

    const db = getFirestore();
    const userDocRef = doc(db, "registeredUsers", userUID);
    const docSnapshot = await getDoc(userDocRef);

    if (docSnapshot.exists()) {
      const userData = docSnapshot.data();
      return {
        uid: userUID,
        ...userData,
      };
    } else {
      console.warn("No se encontró información para el UID proporcionado.");
      return null;
    }
  } catch (error) {
    console.error("Error obteniendo los datos completos del usuario:", error);
    return null;
  }
};

export default getCompleteUserData;
