import React from "react";
import "../styles/Modal.css";

function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div className="modalOverlay">
      <div className="modalContent">
        <div className="closeButtonDiv">
          {" "}
          <button className="closeButton" onClick={onClose}>
            X
          </button>
        </div>
        {children} {/* Contenido dinámico */}
      </div>
    </div>
  );
}

export default Modal;
