import React, { useEffect, useState } from "react";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";

const PatientEvolutions = ({ selectedPatient }) => {
  const [evolutions, setEvolutions] = useState({});
  const [error, setError] = useState("");

  useEffect(() => {
    if (!selectedPatient) return;

    const db = getFirestore();
    const patientRef = doc(db, "registeredPatients", selectedPatient.id);

    // Configurar el listener de Firestore
    const unsubscribe = onSnapshot(
      patientRef,
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setEvolutions(data.evolutions || {}); // Actualizar evoluciones
        } else {
          console.error("El documento no existe.");
          setEvolutions({});
        }
      },
      (error) => {
        console.error("Error al escuchar los cambios:", error);
        setError("Hubo un problema al cargar las evoluciones.");
      }
    );

    // Cleanup del listener cuando el componente se desmonta
    return () => unsubscribe();
  }, [selectedPatient]);

  return (
    <div className="patientEvolutions">
      {error && <p style={{ color: "red" }}>{error}</p>}

      {/* Validar si hay evoluciones */}
      {evolutions && Object.keys(evolutions).length > 0 ? (
        <ul className="evolutionsList">
          {/* Mapear las evoluciones */}
          {Object.entries(evolutions).map(([key, value], index) => (
            <li key={index} className="evolutionItem">
              {value.observation} | {value.user} - {value.date}
            </li>
          ))}
        </ul>
      ) : (
        <p>Aún no se agregaron evoluciones.</p>
      )}
    </div>
  );
};

export default PatientEvolutions;
