/*
import React, { useEffect, useState } from "react";
import { getFirestore, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";

const DeleteFile = ({ selectedPatient, onClose }) => {
  const [files, setFiles] = useState({});
  const [selectedFile, setSelectedFile] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (!selectedPatient) return;

    const db = getFirestore();
    const patientRef = doc(db, "registeredPatients", selectedPatient.id);

    // Escuchar cambios en los archivos
    const unsubscribe = onSnapshot(
      patientRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          setFiles(data.files || {});
        } else {
          setFiles({});
        }
      },
      (error) => {
        console.error("Error al cargar archivos:", error);
        setError("No se pudieron cargar los archivos.");
      }
    );

    return () => unsubscribe();
  }, [selectedPatient]);

  const handleDelete = async () => {
    if (!selectedFile) {
      setError("Selecciona un archivo para eliminar.");
      return;
    }

    try {
      const db = getFirestore();
      const storage = getStorage();

      // Eliminar el archivo de Firebase Storage
      const fileRef = ref(
        storage,
        `patientFiles/${selectedPatient.id}/${selectedFile}`
      );
      await deleteObject(fileRef);

      // Actualizar Firestore para eliminar la referencia
      const patientRef = doc(db, "registeredPatients", selectedPatient.id);
      const updatedFiles = { ...files };
      delete updatedFiles[selectedFile];

      await updateDoc(patientRef, { files: updatedFiles });

      setSuccess(`Archivo "${selectedFile}" eliminado con éxito.`);
      setSelectedFile("");
    } catch (error) {
      console.error("Error al eliminar archivo:", error);
      setError("Hubo un problema al eliminar el archivo.");
    }
  };

  return (
    <div>
      <h3>Eliminar Archivo</h3>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}
      <select
        value={selectedFile}
        onChange={(e) => setSelectedFile(e.target.value)}
        style={{ width: "100%", marginBottom: "10px" }}
      >
        <option value="">Selecciona un archivo</option>
        {Object.keys(files).map((fileName) => (
          <option key={fileName} value={fileName}>
            {fileName}
          </option>
        ))}
      </select>
      <button onClick={handleDelete} disabled={!selectedFile}>
        Eliminar
      </button>
      <button onClick={onClose} style={{ marginLeft: "10px" }}>
        Cancelar
      </button>
    </div>
  );
};

export default DeleteFile;
*/

import React, { useEffect, useState } from "react";
import { getFirestore, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";

const DeleteFile = ({ selectedPatient, onClose }) => {
  const [files, setFiles] = useState({});
  const [selectedFile, setSelectedFile] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (!selectedPatient) return;

    const db = getFirestore();
    const patientRef = doc(db, "registeredPatients", selectedPatient.id);

    // Escuchar cambios en los archivos del paciente
    const unsubscribe = onSnapshot(
      patientRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          setFiles(data.files || {}); // Actualizar listado de archivos
        } else {
          setFiles({});
          setError("No se encontraron archivos para este paciente.");
        }
      },
      (error) => {
        console.error("Error al cargar archivos:", error);
        setError("Hubo un problema al cargar los archivos.");
      }
    );

    return () => unsubscribe(); // Cleanup del listener
  }, [selectedPatient]);

  const handleDelete = async () => {
    if (!selectedFile) {
      setError("Selecciona un archivo para eliminar.");
      return;
    }

    try {
      const db = getFirestore();
      const storage = getStorage();

      // Eliminar archivo de Firebase Storage
      const fileRef = ref(
        storage,
        `patientFiles/${selectedPatient.id}/${selectedFile}`
      );
      await deleteObject(fileRef);

      // Actualizar Firestore para eliminar la referencia
      const patientRef = doc(db, "registeredPatients", selectedPatient.id);
      const updatedFiles = { ...files };

      if (updatedFiles[selectedFile]) {
        delete updatedFiles[selectedFile]; // Eliminar la entrada seleccionada
        await updateDoc(patientRef, { files: updatedFiles }); // Actualizar Firestore
        setSuccess(`Archivo "${selectedFile}" eliminado con éxito.`);
      } else {
        setError("No se encontró el archivo en Firestore.");
      }

      setSelectedFile("");
    } catch (error) {
      console.error("Error al eliminar archivo:", error);
      setError("Hubo un problema al eliminar el archivo.");
    }
  };

  return (
    <div>
      <h3>Eliminar Archivo</h3>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}
      <select
        className="generalFormInput"
        value={selectedFile}
        onChange={(e) => setSelectedFile(e.target.value)}
        style={{ width: "100%", marginBottom: "10px" }}
      >
        <option value="">Selecciona un archivo</option>
        {Object.keys(files).map((fileName) => (
          <option key={fileName} value={fileName}>
            {fileName}
          </option>
        ))}
      </select>
      <button onClick={handleDelete} disabled={!selectedFile}>
        Eliminar
      </button>
    </div>
  );
};

export default DeleteFile;
