import React, { useState, useEffect } from "react";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import getUserGroup from "../../shared/components/getUserGroup.js";

const CreatePatient = () => {
  const [group, setGroup] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    document: "",
    email: "",
    phone: "",
    gender: "",
    country: "",
    city: "",
    birthdate: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    const fetchGroup = async () => {
      try {
        const userGroup = await getUserGroup();
        setGroup(userGroup);
      } catch (error) {
        console.error("Error al obtener el grupo del usuario:", error);
        setError("No se pudo obtener el grupo del usuario.");
      }
    };
    fetchGroup();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.name ||
      !formData.surname ||
      !formData.document ||
      !formData.email ||
      !formData.phone ||
      !formData.gender ||
      !formData.country ||
      !formData.city ||
      !formData.birthdate
    ) {
      setError("Todos los campos son obligatorios.");
      return;
    }

    try {
      const db = getFirestore();
      const patientsCollection = collection(db, "registeredPatients");

      await addDoc(patientsCollection, {
        ...formData,
        group,
      });

      setSuccess("Paciente creado con éxito.");
      setFormData({
        name: "",
        surname: "",
        document: "",
        email: "",
        phone: "",
        gender: "",
        country: "",
        city: "",
        birthdate: "",
      });
      setError("");
    } catch (error) {
      console.error("Error al crear el paciente:", error);
      setError("No se pudo guardar el paciente. Intenta de nuevo.");
    }
  };

  return (
    <div>
      <h2>Crear Paciente</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="Nombre"
            className="generalFormInput"
          />
        </div>
        <div>
          <input
            type="text"
            name="surname"
            value={formData.surname}
            onChange={handleInputChange}
            placeholder="Apellido"
            className="generalFormInput"
          />
        </div>
        <div>
          <input
            type="text"
            name="document"
            value={formData.document}
            onChange={handleInputChange}
            placeholder="Número de documento"
            className="generalFormInput"
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Email"
            className="generalFormInput"
          />
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            placeholder="Teléfono"
            className="generalFormInput"
          />
          <select
            name="gender"
            value={formData.gender}
            onChange={handleInputChange}
            className="generalFormInput"
          >
            <option value="">-- Género --</option>
            <option value="Masculino">Masculino</option>
            <option value="Femenino">Femenino</option>
          </select>
          <input
            type="text"
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            placeholder="País"
            className="generalFormInput"
          />
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleInputChange}
            placeholder="Ciudad"
            className="generalFormInput"
          />
          <input
            type="date"
            name="birthdate"
            value={formData.birthdate}
            onChange={handleInputChange}
            placeholder="Fecha de nacimiento"
            className="generalFormInput"
          />
        </div>
        <button type="submit">Guardar</button>
      </form>
    </div>
  );
};

export default CreatePatient;
