import React, { useEffect, useState } from "react";
import { getFirestore, doc, updateDoc, onSnapshot } from "firebase/firestore";

const DeleteEvolution = ({ selectedPatient, onClose }) => {
  const [evolutions, setEvolutions] = useState({});
  const [selectedEvolution, setSelectedEvolution] = useState("");

  useEffect(() => {
    const db = getFirestore();
    const patientRef = doc(db, "registeredPatients", selectedPatient.id);

    // Escuchar en tiempo real las evoluciones
    const unsubscribe = onSnapshot(patientRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        setEvolutions(data.evolutions || {});
      }
    });

    return () => unsubscribe();
  }, [selectedPatient]);

  const handleDelete = async () => {
    try {
      const db = getFirestore();
      const patientRef = doc(db, "registeredPatients", selectedPatient.id);

      // Filtrar la evolución seleccionada
      const updatedEvolutions = { ...evolutions };
      delete updatedEvolutions[selectedEvolution];

      // Actualizar Firestore con el nuevo mapa de evoluciones
      await updateDoc(patientRef, {
        evolutions: updatedEvolutions,
      });

      onClose(); // Cerrar modal
    } catch (error) {
      console.error("Error al eliminar la evolución:", error);
    }
  };

  return (
    <div>
      <h2>Eliminar Evolución</h2>
      <select
        value={selectedEvolution}
        onChange={(e) => setSelectedEvolution(e.target.value)}
        className="generalFormInput"
      >
        <option value="">Selecciona una evolución</option>
        {Object.entries(evolutions).map(([key, evolution]) => (
          <option key={key} value={key}>
            {evolution.observation} | {evolution.date}
          </option>
        ))}
      </select>
      <button onClick={handleDelete} disabled={!selectedEvolution}>
        Eliminar
      </button>
    </div>
  );
};

export default DeleteEvolution;
