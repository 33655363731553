import React, { useEffect, useState } from "react";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";

const FileList = ({ selectedPatient }) => {
  const [files, setFiles] = useState({});
  const [error, setError] = useState("");

  useEffect(() => {
    if (!selectedPatient) return;

    const db = getFirestore();
    const patientRef = doc(db, "registeredPatients", selectedPatient.id);

    // Configurar el listener para obtener archivos en tiempo real
    const unsubscribe = onSnapshot(
      patientRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setFiles(data.files || {}); // Actualizar listado de archivos
        } else {
          setFiles({});
          setError("No se encontraron archivos para este paciente.");
        }
      },
      (error) => {
        console.error("Error al obtener archivos:", error);
        setError("Hubo un problema al cargar los archivos.");
      }
    );

    return () => unsubscribe(); // Cleanup del listener
  }, [selectedPatient]);

  return (
    <div className="patientFiles">
      {error && <p style={{ color: "red" }}>{error}</p>}
      {Object.keys(files).length > 0 ? (
        <ul className="fileList">
          {Object.entries(files).map(([fileName, fileURL], index) => (
            <li className="fileItem" key={index}>
              <a href={fileURL} target="_blank" rel="noopener noreferrer">
                {fileName}
              </a>
            </li>
          ))}
        </ul>
      ) : (
        <p>Aún no se agregaron archivos.</p>
      )}
    </div>
  );
};

export default FileList;
