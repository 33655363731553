/*
import React from "react";

const PatientHistory = ({ selectedPatient }) => {
  // Extraer el campo background del paciente
  const { background } = selectedPatient || {};

  return (
    <div className="patientHistory">
      {background && Object.keys(background).length > 0 ? (
        <ul className="historyList">
          {Object.entries(background).map(([key, value], index) => (
            <li key={index} className="historyItem">
              <strong>{key}:</strong> {value}
            </li>
          ))}
        </ul>
      ) : (
        <p>No hay antecedentes disponibles.</p>
      )}
    </div>
  );
};

export default PatientHistory;
*/

import React, { useEffect, useState } from "react";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";

const PatientHistory = ({ selectedPatient }) => {
  const [background, setBackground] = useState({});
  const [error, setError] = useState("");

  useEffect(() => {
    if (!selectedPatient) return;

    const db = getFirestore();
    const patientRef = doc(db, "registeredPatients", selectedPatient.id);

    // Configurar el listener de Firestore
    const unsubscribe = onSnapshot(
      patientRef,
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setBackground(data.background || {}); // Actualizar antecedentes
        } else {
          console.error("El documento no existe.");
          setBackground({});
        }
      },
      (error) => {
        console.error("Error al escuchar los cambios:", error);
        setError("Hubo un problema al cargar los antecedentes.");
      }
    );

    // Cleanup del listener cuando el componente se desmonta
    return () => unsubscribe();
  }, [selectedPatient]);

  return (
    <div className="patientHistory">
      {error && <p style={{ color: "red" }}>{error}</p>}

      {/* Validar si hay antecedentes */}
      {background && Object.keys(background).length > 0 ? (
        <ul className="historyList">
          {/* Mapear los antecedentes */}
          {Object.entries(background).map(([key, value], index) => (
            <li key={index} className="historyItem">
              <strong>{key}:</strong> {value}
            </li>
          ))}
        </ul>
      ) : (
        <p>Aún no se agregaron antecedentes.</p>
      )}
    </div>
  );
};

export default PatientHistory;
