import React, { useState } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import getUserGroup from "../../shared/components/getUserGroup.js";

const PatientSearch = ({ onSelectPatient }) => {
  const [searchValue, setSearchValue] = useState("");
  const [results, setResults] = useState([]);
  const [error, setError] = useState("");

  const handleSearch = async (e) => {
    e.preventDefault();

    try {
      setError("");
      const db = getFirestore();
      const group = await getUserGroup();

      if (!group) {
        setError("No se pudo obtener el grupo del usuario.");
        return;
      }

      if (!searchValue.trim()) {
        setError("Por favor, ingresa un criterio de búsqueda.");
        return;
      }

      const patientsCollection = collection(db, "registeredPatients");

      const searchQuery = query(
        patientsCollection,
        where("group", "==", group)
      );

      const querySnapshot = await getDocs(searchQuery);

      const patients = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter(
          (patient) =>
            patient.document === searchValue ||
            patient.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            patient.surname.toLowerCase().includes(searchValue.toLowerCase())
        );

      if (patients.length === 0) {
        setError("No se encontraron pacientes.");
      } else {
        setResults(patients);
      }
    } catch (error) {
      console.error("Error al buscar pacientes:", error);
      setError("Ocurrió un error al buscar pacientes.");
    }
  };

  return (
    <div>
      <h2>Buscar Pacientes</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Buscar por nombre, apellido o documento"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          className="generalFormInput"
        />
        <button type="submit">Buscar</button>
      </form>

      <ul className="patientSearchResults">
        {results.map((patient) => (
          <li
            key={patient.id}
            onClick={() =>
              typeof onSelectPatient === "function" && onSelectPatient(patient)
            }
          >
            {patient.name} {patient.surname} - Documento: {patient.document}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PatientSearch;
