/*
import React, { useState } from "react";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getFirestore, doc, updateDoc } from "firebase/firestore";

const UploadFile = ({ selectedPatient }) => {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const storage = getStorage();
  const db = getFirestore();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file || !selectedPatient) {
      setError("Selecciona un archivo y un paciente válido.");
      return;
    }

    try {
      const storageRef = ref(
        storage,
        `patientFiles/${selectedPatient.id}/${file.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Calcular progreso
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error al subir el archivo:", error);
          setError("Error al subir el archivo.");
        },
        async () => {
          // Obtener URL del archivo
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          // Guardar la URL en Firestore dentro del paciente seleccionado
          const patientRef = doc(db, "registeredPatients", selectedPatient.id);
          await updateDoc(patientRef, {
            files: {
              ...(selectedPatient.files || {}),
              [file.name]: downloadURL,
            },
          });

          setSuccess("Archivo subido con éxito.");
          setUploadProgress(0);
        }
      );
    } catch (error) {
      console.error("Error en la subida:", error);
      setError("Hubo un problema al subir el archivo.");
    }
  };

  return (
    <div>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}
      {uploadProgress > 0 && <p>Progreso: {Math.round(uploadProgress)}%</p>}
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload} className="smallButton">
        Agregar
      </button>
    </div>
  );
};

export default UploadFile;
*/

import React, { useState } from "react";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getFirestore, doc, updateDoc } from "firebase/firestore";

const UploadFile = ({ selectedPatient, onClose }) => {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const storage = getStorage();
  const db = getFirestore();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError("");
    setSuccess("");
  };

  const handleUpload = async () => {
    if (!file || !selectedPatient) {
      setError("Selecciona un archivo y un paciente válido.");
      return;
    }

    try {
      const storageRef = ref(
        storage,
        `patientFiles/${selectedPatient.id}/${file.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error al subir el archivo:", error);
          setError("Error al subir el archivo.");
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          const patientRef = doc(db, "registeredPatients", selectedPatient.id);
          await updateDoc(patientRef, {
            files: {
              ...(selectedPatient.files || {}),
              [file.name]: downloadURL,
            },
          });

          setSuccess("Archivo subido con éxito.");
          setUploadProgress(0);
          setFile(null); // Reiniciar el input de archivo
          onClose(); // Cerrar el modal después de subir
        }
      );
    } catch (error) {
      console.error("Error en la subida:", error);
      setError("Hubo un problema al subir el archivo.");
    }
  };

  return (
    <div>
      <h3>Subir Archivo</h3>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}
      {uploadProgress > 0 && <p>Progreso: {Math.round(uploadProgress)}%</p>}
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload} className="smallButton">
        Subir
      </button>
      <button
        onClick={onClose}
        className="smallButton"
        style={{ marginLeft: "10px" }}
      >
        Cancelar
      </button>
    </div>
  );
};

export default UploadFile;
