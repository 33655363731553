import React, { useState } from "react";
import { getFirestore, doc, updateDoc } from "firebase/firestore";

const AddHistory = ({ selectedPatient, onClose }) => {
  const [key, setKey] = useState(""); // Clave del antecedente (ejemplo: "Alergia")
  const [value, setValue] = useState(""); // Valor del antecedente (ejemplo: "Penicilina")
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!key.trim() || !value.trim()) {
      setError("Ambos campos son obligatorios.");
      return;
    }

    try {
      const db = getFirestore();
      const patientRef = doc(db, "registeredPatients", selectedPatient.id);

      // Actualizar Firestore (merge con el campo `background`)
      await updateDoc(patientRef, {
        [`background.${key}`]: value, // Agrega o actualiza la clave dentro del objeto `background`
      });

      setSuccess("Antecedente agregado con éxito.");
      setError("");
      setKey("");
      setValue("");
    } catch (error) {
      console.error("Error al agregar el antecedente:", error);
      setError("Hubo un problema al guardar el antecedente.");
    }
  };

  return (
    <div className="addHistory">
      <h2>Agregar Antecedente</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}

      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            placeholder="Tipo de antecedente"
            value={key}
            onChange={(e) => setKey(e.target.value)}
            className="generalFormInput"
          />
        </div>
        <div>
          <input
            type="text"
            placeholder="Observación"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="generalFormInput"
          />
        </div>
        <button type="submit">Guardar</button>
      </form>
    </div>
  );
};

export default AddHistory;
