/*
import { getFirestore, doc, getDoc } from "firebase/firestore";
import getUserUID from "./getUserUID";

const getUserGroup = async () => {
  try {
    const db = getFirestore();
    const uid = await getUserUID();

    if (!uid) {
      throw new Error("No hay un usuario autenticado.");
    }

    const userDocRef = doc(db, "registeredUsers", uid);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      throw new Error("El documento del usuario no existe en Firestore.");
    }

    const userData = userDoc.data();
    return userData.group || null;
  } catch (error) {
    console.error("Error al obtener el grupo del usuario:", error);
    throw error;
  }
};

export default getUserGroup;
*/

/*
import { getFirestore, doc, getDoc } from "firebase/firestore";
import getUserUID from "./getUserUID";

const getUserGroup = async () => {
  try {
    const db = getFirestore();
    const uid = await getUserUID();

    if (!uid) {
      throw new Error("No hay un usuario autenticado.");
    }

    const userDocRef = doc(db, "registeredUsers", uid);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      throw new Error("El documento del usuario no existe en Firestore.");
    }

    const userData = userDoc.data();
    return {
      group: userData.group || null,
      name: userData.name || "Desconocido",
      surname: userData.surname || "Desconocido",
    };
  } catch (error) {
    console.error("Error al obtener los datos del usuario:", error);
    throw error;
  }
};

export default getUserGroup;
*/

/*
import { getFirestore, doc, getDoc } from "firebase/firestore";
import getUserUID from "./getUserUID";

const getUserGroup = async (includeDetails = false) => {
  try {
    const db = getFirestore();
    const uid = await getUserUID();

    if (!uid) {
      throw new Error("No hay un usuario autenticado.");
    }

    const userDocRef = doc(db, "registeredUsers", uid);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      throw new Error("El documento del usuario no existe en Firestore.");
    }

    const userData = userDoc.data();

    // Devolver solo el grupo si no se requieren detalles
    if (!includeDetails) {
      return userData.group || null;
    }

    // Devolver nombre, apellido y grupo si includeDetails es true
    return {
      group: userData.group || null,
      name: userData.name || "Desconocido",
      surname: userData.surname || "Desconocido",
    };
  } catch (error) {
    console.error("Error al obtener los datos del usuario:", error);
    throw error;
  }
};

export default getUserGroup;
*/

import { getFirestore, doc, getDoc } from "firebase/firestore";
import getUserUID from "./getUserUID";

const getUserGroup = async (includeDetails = false) => {
  try {
    const db = getFirestore();
    const uid = await getUserUID();

    if (!uid) {
      throw new Error("No hay un usuario autenticado.");
    }

    const userDocRef = doc(db, "registeredUsers", uid);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      throw new Error("El documento del usuario no existe en Firestore.");
    }

    const userData = userDoc.data();

    // Retornar solo el grupo por defecto
    if (!includeDetails) {
      return userData.group || null;
    }

    // Retornar grupo, nombre y apellido cuando includeDetails es true
    return {
      group: userData.group || null,
      name: userData.name || "Desconocido",
      surname: userData.surname || "Desconocido",
    };
  } catch (error) {
    console.error("Error al obtener los datos del usuario:", error);
    throw error;
  }
};

export default getUserGroup;
