import { getFirestore, doc, updateDoc } from "firebase/firestore";

const updateUserData = async (uid, newData) => {
  try {
    const db = getFirestore();
    const userDocRef = doc(db, "registeredUsers", uid);

    await updateDoc(userDocRef, newData);
    console.log("Datos del usuario actualizados correctamente.");
    return true;
  } catch (error) {
    console.error("Error actualizando los datos del usuario:", error);
    return false;
  }
};

export default updateUserData;
