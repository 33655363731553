import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./features/shared/context/AuthContext";
import ProtectedRoute from "./features/shared/components/ProtectedRoute";
import React from "react";
import Login from "./features/auth/pages/Login";
import Account from "./features/account/pages/Account";
import Patients from "./features/patients/pages/Patients";
import Sidebar from "./features/shared/components/Sidebar";

import "material-icons/iconfont/material-icons.css";

function App() {
  return (
    <AuthProvider>
      <Router>
        <ProtectedRoute>
          <Sidebar />
        </ProtectedRoute>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="patients"
            element={
              <ProtectedRoute>
                <Patients />
              </ProtectedRoute>
            }
          />
          <Route
            path="/account"
            element={
              <ProtectedRoute>
                <Account />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
