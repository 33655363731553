import React, { useEffect, useState } from "react";
import "../styles/Account.css";
import "../../shared/styles/Global.css";
import getCompleteUserData from "../components/getUserData";
import updateUserData from "../components/updateUserData";

const Account = () => {
  const [userData, setUserData] = useState(null);
  const [editData, setEditData] = useState({ name: "", surname: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getCompleteUserData();
      if (data) {
        setUserData(data);
        setEditData({ name: data.name, surname: data.surname });
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  const handleUpdate = async () => {
    if (!userData?.uid) return;
    setLoading(true);
    const success = await updateUserData(userData.uid, editData);
    setLoading(false);
    if (success) {
      setUserData({ ...userData, ...editData });
      alert("Datos actualizados correctamente.");
    } else {
      alert("Hubo un error al actualizar los datos.");
    }
  };

  return (
    <section className="accountSection">
      {userData ? (
        <div className="accountContainer">
          <p>
            <label className="generalFormLabel">
              Nombre
              <input
                type="text"
                name="name"
                value={editData.name}
                onChange={handleInputChange}
                className="generalFormInput"
              />
            </label>
          </p>
          <p>
            <label className="generalFormLabel">
              Apellido
              <input
                type="text"
                name="surname"
                value={editData.surname}
                onChange={handleInputChange}
                className="generalFormInput"
              />
            </label>
          </p>
          <p>
            <label className="generalFormLabel">
              Email{" "}
              <input
                type="text"
                value={userData.email}
                readOnly
                className="generalFormInput"
              />
            </label>
          </p>
          <button onClick={handleUpdate} disabled={loading}>
            {loading ? "Guardando..." : "Guardar Cambios"}
          </button>
        </div>
      ) : (
        <p>Cargando información del usuario...</p>
      )}
    </section>
  );
};

export default Account;
