import React, { useState } from "react";
import Modal from "../../shared/components/Modal.js";
import "../styles/Patients.css";
import "../../shared/styles/Global.css";

import CreatePatient from "../components/createPatient.js";
import PatientSearch from "../components/PatientSearch.js";
import PatientHistory from "../components/PatientHistory.js";
import AddHistory from "../components/AddHistory.js";
import DeleteHistory from "../components/DeleteHistory.js";
import AddEvolution from "../components/AddEvolution.js";
import PatientEvolutions from "../components/PatientEvolutions.js";
import DeleteEvolution from "../components/DeleteEvolution.js";
import UploadFile from "../components/UploadFile.js";
import FileList from "../components/FileList.js";
import DeleteFile from "../components/DeleteFile.js";

function Patients() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const handleSelectPatient = (patient) => {
    setSelectedPatient(patient);
    console.log("Paciente seleccionado:", patient);
    closeModal();
  };

  //CODIGO PARA EL CALCULO DE LA EDAD
  const calculateAge = (birthdate) => {
    if (!birthdate) return "No disponible";
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--; // Ajustar si el cumpleaños aún no ha ocurrido este año
    }
    return age;
  };

  return (
    <section className="patientsSection">
      <div>
        <button className="icon-button" onClick={() => openModal("create")}>
          Crear
        </button>

        <button className="icon-button" onClick={() => openModal("search")}>
          Buscar
        </button>
      </div>

      {selectedPatient && (
        <div className="dataContainer">
          <div>
            {/* Mostrar detalles del paciente seleccionado */}
            {selectedPatient && (
              <div className="patientDetails">
                <h2>Paciente</h2>
                <label className="generalFormLabel">Nombre</label>
                <input
                  type="text"
                  className="generalFormInput"
                  value={selectedPatient.name}
                  readOnly
                />
                <label className="generalFormLabel">Apellido</label>
                <input
                  type="text"
                  className="generalFormInput"
                  value={selectedPatient.surname}
                  readOnly
                />
                <label className="generalFormLabel">Documento</label>
                <input
                  type="text"
                  className="generalFormInput"
                  value={selectedPatient.document}
                  readOnly
                />
                <label className="generalFormLabel">Email</label>
                <input
                  type="email"
                  className="generalFormInput"
                  value={selectedPatient.email}
                  readOnly
                />
                <label className="generalFormLabel">Teléfono</label>
                <input
                  type="tel"
                  className="generalFormInput"
                  value={selectedPatient.phone}
                  readOnly
                />
                <label className="generalFormLabel">Fecha de nacimiento</label>
                <input
                  type="date"
                  className="generalFormInput"
                  value={selectedPatient.birthdate}
                  readOnly
                />
                <label className="generalFormLabel">Edad</label>

                <input
                  type="text"
                  className="generalFormInput"
                  value={calculateAge(selectedPatient.birthdate)}
                  readOnly
                />
                <label className="generalFormLabel">Género</label>
                <input
                  type="text"
                  className="generalFormInput"
                  value={selectedPatient.gender}
                  readOnly
                />
                <label className="generalFormLabel">País</label>
                <input
                  type="text"
                  className="generalFormInput"
                  value={selectedPatient.country}
                  readOnly
                />
                <label className="generalFormLabel">Ciudad</label>
                <input
                  type="text"
                  className="generalFormInput"
                  value={selectedPatient.city}
                  readOnly
                />
                <button className="smallButton">Editar datos</button>
              </div>
            )}
          </div>
          <div>
            <div className="provisorioDos">
              <h2>Antecedentes</h2>

              {selectedPatient && (
                <PatientHistory selectedPatient={selectedPatient} />
              )}

              <div className="provisorioCuatro">
                <button
                  className="smallButton"
                  onClick={() => openModal("addHistory")}
                >
                  Agregar
                </button>

                <button
                  className="smallButton"
                  onClick={() => openModal("deleteHistory")}
                >
                  Eliminar
                </button>
              </div>
            </div>

            <div className="provisorioNueve">
              <h2>Evoluciones</h2>

              {selectedPatient && (
                <PatientEvolutions selectedPatient={selectedPatient} />
              )}

              <div className="provisorioCuatro">
                <button
                  className="smallButton"
                  onClick={() => openModal("addEvolution")}
                >
                  Agregar
                </button>

                <button
                  className="smallButton"
                  onClick={() => openModal("deleteEvolution")}
                >
                  Eliminar
                </button>
              </div>
            </div>

            <div className="provisorioNueve">
              <h2>Archivos</h2>

              {selectedPatient && (
                <div>
                  {/* Mostrar listado de archivos */}
                  <FileList selectedPatient={selectedPatient} />
                </div>
              )}

              <div className="provisorioCuatro">
                <button
                  className="smallButton"
                  onClick={() => openModal("uploadFile")}
                >
                  Agregar
                </button>
                <button
                  className="smallButton"
                  onClick={() => openModal("deleteFile")}
                >
                  Eliminar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Modal dinámico */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {modalContent === "create" && <CreatePatient />}
        {modalContent === "search" && (
          <PatientSearch onSelectPatient={handleSelectPatient} />
        )}

        {modalContent === "addHistory" && (
          <AddHistory selectedPatient={selectedPatient} onClose={closeModal} />
        )}

        {modalContent === "deleteHistory" && (
          <DeleteHistory
            selectedPatient={selectedPatient}
            onClose={closeModal}
          />
        )}

        {modalContent === "addEvolution" && (
          <AddEvolution
            selectedPatient={selectedPatient}
            onClose={closeModal}
          />
        )}

        {modalContent === "deleteEvolution" && (
          <DeleteEvolution
            selectedPatient={selectedPatient}
            onClose={closeModal}
          />
        )}

        {modalContent === "uploadFile" && (
          <UploadFile selectedPatient={selectedPatient} onClose={closeModal} />
        )}

        {modalContent === "deleteFile" && (
          <DeleteFile selectedPatient={selectedPatient} onClose={closeModal} />
        )}
      </Modal>
    </section>
  );
}

export default Patients;
